<template>
  <div :class="$style.container">
    <tabset
      v-if="type === 2 || type === 5"
      class="linkTabs"
      :tabs="tabs"
      :opened="true"
      :hide-arrow="true"
      :class="$style.tabs"
      :tab-style="tabStyle"
      @tabClick="openNewTab"
    />
    <div :class="$style.table">
      <div :class="$style.buttonsContainer">
        <button
          :class="[$style.addUserButton, $style.buttonContainer]"
          @click="openModal('addRule')"
        >
          Добавить правило
        </button>

        <button
          :class="[$style.addUserButton, $style.buttonContainer]"
          @click="openModal('addGroup')"
        >
          Добавить группу правил
        </button>
      </div>
      <dragTreeTable
        v-if="type === 1"
        :data="treeDataExt"
        :before-drag-over="allowDrag"
        :on-drag="onTreeDataChange"
        @deleteItem="openDeleteModal"
        @saveNewName="changeName"
        @update="getSettings()"
      />
      <dragTreeTable
        v-else-if="type === 2"
        :data="treeDataForbidden"
        :before-drag-over="allowDrag"
        :on-drag="onTreeDataChange"
        @deleteItem="openDeleteModal"
        @saveNewName="changeName"
        @update="getSettings()"
      />
      <dragTreeTable
        v-else
        :data="treeData"
        :before-drag-over="allowDrag"
        :on-drag="onTreeDataChange"
        @deleteItem="openDeleteModal"
        @saveNewName="changeName"
        @update="getSettings()"
      />
    </div>
    <delete-modal
      id="deleteRule"
      :class="$style.modal"
      :selected="selectedItem"
      :type="modalType"
      @close="closeModal('deleteRule')"
      @update="getSettings()"
    />
    <add-category
      id="addGroup"
      :class="$style.modal"
      :type="type"
      @close="closeModal('addGroup')"
      @update="getSettings()"
    />
    <add-rule
      id="addRule"
      :class="$style.modal"
      :type="type"
      @close="closeModal('addRule')"
      @update="getSettings()"
    />
  </div>
</template>

<script>
import {
  getSecurityRules,
  editRule,
  editCategory,
  linkCategory,
} from '@/api/methods/security';
import dragTreeTable from '@/components/pages/agents/drag-tree-table/dragTreeTable.vue';
import DeleteModal from '@/views/settings/agents/modals/deleteModal.vue';
import AddCategory from '@/views/settings/agents/agent/security/add-category.vue';
import AddRule from '@/views/settings/agents/agent/security/add-rule.vue';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';

export default {
  components: {
    dragTreeTable,
    DeleteModal,
    AddCategory,
    AddRule,
    Tabset,
  },
  props: ['type'],
  data: () => ({
    tabs: [
      {
        title: 'Запрещенные',
        active: true,
        link: 'forbidden',
        type: 2,
      },
      {
        title: 'Разрешенные',
        active: false,
        link: 'allowed',
        type: 5,
      },
    ],
    tabStyle: {
      fontSize: '14px',
    },
    treeDataExt: {
      columns: [
        {
          type: 'checkbox',
          isContainChildren: true,
          title: 'Активное правило',
          field: 'checked',
          maxWidth: '5%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          type: 'selection',
          title: 'Имя exe-файла',
          field: 'title',
          maxWidth: '30%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },

        {
          title: 'Заголовок окна',
          field: 'caption',
          maxWidth: '15%',
          padding: '5px',
          align: 'left',
          flex: 2,
        },
        {
          title: 'Действие',
          field: 'actTypeName',
          maxWidth: '20%',
          padding: '5px',
          align: 'left',
          flex: 2,
        },
        {
          title: 'Комментарий',
          field: 'comment',
          maxWidth: '20%',
          padding: '5px',
          align: 'left',
          flex: 2,
        },
        {
          type: 'action',
          title: 'Действия',
          maxWidth: '10%',
          padding: '5px',
          align: 'left',
          flex: 1,
          actions: [
            {
              text: 'изменить',
              eventName: 'changeName',
              formatter: () => '',
            },
            {
              text: 'сохранить',
              eventName: 'saveNewName',
              formatter: () => '',
            },
            {
              text: 'удалить',
              eventName: 'deleteItem',
              formatter: () => '',
            },
            {
              text: 'отменить',
              eventName: 'cancelChange',
              formatter: () => '',
            },
          ],
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'category',
      },
    },
    treeDataForbidden: {
      columns: [
        {
          type: 'checkbox',
          isContainChildren: true,
          title: 'Активное правило',
          field: 'checked',
          maxWidth: '5%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          type: 'selection',
          title: 'Имя exe-файла',
          field: 'title',
          maxWidth: '30%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          title: 'Действие',
          field: 'actTypeName',
          maxWidth: '30%',
          padding: '5px',
          align: 'left',
          flex: 2,
        },
        {
          title: 'Комментарий',
          field: 'comment',
          maxWidth: '25%',
          padding: '5px',
          align: 'left',
          flex: 2,
        },
        {
          type: 'action',
          title: 'Действия',
          maxWidth: '10%',
          padding: '5px',
          align: 'left',
          flex: 1,
          actions: [
            {
              text: 'изменить',
              eventName: 'changeName',
              formatter: () => '',
            },
            {
              text: 'сохранить',
              eventName: 'saveNewName',
              formatter: () => '',
            },
            {
              text: 'удалить',
              eventName: 'deleteItem',
              formatter: () => '',
            },
            {
              text: 'отменить',
              eventName: 'cancelChange',
              formatter: () => '',
            },
          ],
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'category',
      },
    },
    treeData: {
      columns: [
        {
          type: 'checkbox',
          isContainChildren: true,
          title: 'Активное правило',
          field: 'checked',
          maxWidth: '5%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          type: 'selection',
          title: 'Имя exe-файла',
          field: 'title',
          maxWidth: '50%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          title: 'Комментарий',
          field: 'comment',
          maxWidth: '35%',
          padding: '5px',
          align: 'left',
          flex: 2,
        },
        {
          type: 'action',
          title: 'Действия',
          maxWidth: '10%',
          padding: '5px',
          align: 'left',
          flex: 1,
          actions: [
            {
              text: 'изменить',
              eventName: 'changeName',
              formatter: () => '',
            },
            {
              text: 'сохранить',
              eventName: 'saveNewName',
              formatter: () => '',
            },
            {
              text: 'удалить',
              eventName: 'deleteItem',
              formatter: () => '',
            },
            {
              text: 'отменить',
              eventName: 'cancelChange',
              formatter: () => '',
            },
          ],
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'category',
      },
    },
    result: {},
    categories: [],
    rules: [],
    selectedItem: undefined,
    modalType: undefined,
    actions: [
      { name: 'Только оповещение', id: 35 },
      { name: 'Блокировать запуск', id: 36 },
      { name: 'Блокировать и оповещение', id: 37 },
    ],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  watch: {
    '$route.params.log': async function () {
      await this.getSettings();
      if (this.type === 2) {
        this.tabs[0].active = true;
        this.tabs[1].active = false;
      }
      if (this.type === 5) {
        this.tabs[1].active = true;
        this.tabs[0].active = false;
      }
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    openNewTab(index) {
      const { query } = this.$route;
      this.$router.push({
        name: this.$route.active,
        params: { log: this.tabs[index].link },
        query,
      });

      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
    async onTreeDataChange(list, computer, group, target) {
      if (target !== 'top') {
        let parent = group.ruleId;
        if (target !== 'center') parent = 0;
        await linkCategory(computer.ruleId, parent);

        if (this.type === 1) this.treeDataExt.lists = list;
        else if (this.type === 2) this.treeDataForbidden.lists = list;
        else this.treeData.lists = list;

        await this.getSettings();
      }
    },
    async changeName(model) {
      if (model.type === 'computer') {
        this.actions.forEach((action) => {
          if (action.name === model.actTypeName) model.actType = action.id;
        });

        await editRule(
          model.ruleId,
          model.title,
          model.dang,
          model.comment,
          model.caption,
          model.actType,
        );
      } else editCategory(model.ruleId, model.title);
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    openDeleteModal(model) {
      if (model.type === 'computer') {
        this.modalType = 'rule';
        this.selectedItem = model.ruleId;
      } else {
        this.modalType = 'ruleCategory';
        this.selectedItem = {
          id: model.ruleId,
          profile: this.$store.getters['settings/profile'],
          type: this.type,
        };
      }
      document.getElementById('deleteRule').style.display = 'flex';
    },
    allowDrag(row, row2) {
      if (row2.type === 'computer' || row.type === 'folder') return false;
      return true;
    },
    setNodeAttributes(node) {
      node.ident = node.ruleId + node.name;
      if (node.categoryType) {
        node.type = 'folder';
        node.opened = true;
        node.open = true;
        node.title = node.name;
        node.isContainChildren = true;
        node.children.forEach((child) => this.setNodeAttributes(child));
      } else {
        if (node.id > 0) node.checked = true;
        else node.checked = false;
        node.opened = false;
        node.dropDisabled = true;
        node.title = node.name;
        node.user = node.name;
        node.type = 'computer';

        this.actions.forEach((action) => {
          if (action.id === node.actType) node.actTypeName = action.name;
        });
      }
    },
    async getSettings() {
      this.treeData.lists = [];
      this.treeDataExt.lists = [];
      this.treeDataForbidden.lists = [];
      this.result = {};
      if (this.selectedComputer.id) {
        this.promise = await getSecurityRules(
          this.selectedComputer.id,
          this.selectedComputer.type,
          this.type,
        ).then(({ data }) => {
          if (data) {
            this.categories = data.result.categories;
            this.rules = data.result.rules;
          }
        });

        const result = [];
        for (let i = 0; i < this.categories.length; i++) {
          this.categories[i].children = [];
          for (let j = 0; j < this.rules.length; j++) {
            if (this.rules[j].category === this.categories[i].ruleId) {
              this.categories[i].children.push(this.rules[j]);
            }
          }
          result.push(this.categories[i]);
        }
        for (let x = 0; x < this.rules.length; x++) {
          if (!this.rules[x].category) result.push(this.rules[x]);
        }

        result.forEach((item) => this.setNodeAttributes(item));

        result.sort(this.compare);
        result.forEach((node) => {
          if (node.children) node.children.sort(this.compare);
        });
        /*
        result.forEach((node) => {
          if (node.children) {
            let allChecked = true;
            node.children.forEach((child) => {
              if (!child.checked) allChecked = false;
            });
            node.checked = allChecked;
          }
        });
*/
        const settings = this.$store.getters['settings/security'];
        if (settings.checked.length > 0) {
          settings.checked.forEach((item) => {
            result.forEach((rule) => {
              if (rule.type !== 'folder') {
                if (rule.ruleId === item.ruleId) {
                  rule.checked = true;
                }
              } else {
                rule.children.forEach((child) => {
                  if (child.ruleId === item.ruleId) child.checked = true;
                });
              }
            });
          });
        } else if (settings.unchecked.length > 0) {
          settings.unchecked.forEach((item) => {
            result.forEach((rule) => {
              if (rule.type !== 'folder') {
                if (rule.ruleId === item.ruleId) {
                  rule.checked = false;
                }
              } else {
                rule.children.forEach((child) => {
                  if (child.ruleId === item.ruleId) {
                    child.checked = false;
                  }
                });
              }
            });
          });
        }

        if (this.type === 1) this.treeDataExt.lists = result;
        else if (this.type === 2) this.treeDataForbidden.lists = result;
        else this.treeData.lists = result;
      }
    },
    compare(a, b) {
      const a1 = a.name.toLowerCase();
      const b1 = b.name.toLowerCase();

      if (a1 > b1) {
        return 1;
      }

      if (a1 < b1) {
        return -1;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" module>
.table {
  margin: 10px;
  overflow: auto;
  height: 100%;
  flex-direction: column;
  display: flex;
  min-width: 500px;
}

.modal {
  display: none;
  z-index: 3;
}

.buttonsContainer {
  display: inline-flex;
  width: 100%;
}

.buttonContainer {
  width: 50%;
}

.addUserButton {
  border: 1px solid white;
  background: #2985bf;
  cursor: pointer;
  height: 30px;
  color: white;
  width: 100%;
}

.container {
  overflow: auto;
  height: 100%;
  flex-direction: column;
  display: flex;
}
</style>
