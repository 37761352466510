<template>
  <modal
    header-text="Добавление группы правил"
    button-text="Добавить"
    @action="addGroup()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label> Название группы</label>
      <input v-model="groupName" type="text" :class="$style.input">
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { addCategory } from '@/api/methods/security';

export default {
  components: { Modal },
  props: ['type'],
  data: () => ({
    groupName: '',
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    async addGroup() {
      const result = await addCategory(this.groupName, this.type);
      if (result === 'success') this.groupName = '';
      this.$emit('update');
      this.close();
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 55%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
