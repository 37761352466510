<template>
  <div>
    <modal
      header-text="Добавление пункта безопасности"
      button-text="Добавить"
      @action="add()"
      @close="$emit('close')"
    >
      <div v-if="type === 4">
        <label for="file-upload" class="custom-file-upload">
          Выбрать файл
        </label>
        <input
          id="file-upload"
          ref="doc"
          type="file"
          accept=" .doc, .docx,.xls,.xlsx,.pdf,.ppt,.pptx,.odt,.ods,|Documents/*"
          @change="readFile()"
        >
      </div>

      <button
        v-if="type === 1 || type === 2 || type === 5"
        :class="$style.button"
        @click="openModal('appSelect')"
      >
        Выбрать из списка
      </button>

      <div :class="$style.inputContainer">
        <label> Название</label>
        <input v-model="name" type="text" :class="$style.input">
      </div>

      <div :class="$style.inputContainer">
        <label> Комментарий</label>
        <input v-model="comment" type="text" :class="$style.input">
      </div>

      <div v-if="type === 1" :class="$style.inputContainer">
        <label> Заголовок</label>
        <input v-model="caption" type="text" :class="$style.input">
      </div>

      <div v-if="type === 1 && type === 2" :class="$style.inputContainer">
        <label> Действие</label>
        <select v-model="selectedAction" :class="$style.input">
          <option v-for="action in actions" :key="action.id" :value="action.id">
            {{ action.name }}
          </option>
        </select>
      </div>

      <div :class="$style.inputContainer">
        <label> Уровень безопасности</label>
        <select v-model="selectedDang" :class="$style.input">
          <option
            v-for="option in dangOptions"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </modal>
    <select-modal
      id="appSelect"
      :ref="'appSelect' + type"
      :class="$style.appModal"
      :type="type"
      @close="closeModal('appSelect')"
      @input="input"
    />
  </div>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { addRule } from '@/api/methods/security';
import SelectModal from '@/views/settings/agents/agent/security/select-modal.vue';

const md5 = require('md5');

export default {
  components: { Modal, SelectModal },
  props: ['type'],
  data: () => ({
    name: '',
    comment: '',
    caption: '',
    selectedAction: 35,
    selectedDang: 1,
    actions: [
      { name: 'Только оповещение', id: 35 },
      { name: 'Блокировать запуск', id: 36 },
      { name: 'Блокировать и оповещение', id: 37 },
    ],
    dangOptions: [
      { name: '1 уровень', id: 1 },
      { name: '2 уровень', id: 2 },
    ],
    file: null,
    content: null,
  }),
  methods: {
    input(name) {
      this.closeModal('appSelect');
      this.name = name;
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async readFile() {
      this.file = this.$refs.doc.files[0];
      const reader = new FileReader();
      const conditions = [
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
        '.pdf',
        '.ppt',
        '.pptx',
        '.odt',
        '.ods',
      ];

      if (conditions.some((el) => this.file.name.includes(el))) {
        reader.onload = async (res) => {
          const arrayBuffer = res.target.result;
          const array = new Uint8Array(arrayBuffer);
          this.name = md5(array);
        };
        reader.readAsArrayBuffer(this.file);
      }
    },
    close() {
      this.$emit('close');
    },
    async add() {
      const result = await addRule(
        this.name,
        this.selectedDang,
        this.type,
        this.comment,
        this.caption,
        this.selectedAction,
      );
      if (result === 'success') {
        this.name = '';
        this.$emit('update');
        this.close();
        if (this.type === 1 || this.type === 2 || this.type === 5) {
          this.$refs[`appSelect${this.type}`].getApps();
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 55%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.appModal {
  display: none;
  z-index: 5;
}

.button {
  margin: 5px;
  height: 30px;
  cursor: pointer;
  width: 150px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
</style>

<style>
input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 36px;
  background: #2985bf;
  padding: 9px 12px;
  display: inline-block;
  margin: 5px;
}
</style>
