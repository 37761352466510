<template>
  <div :class="$style.container">
    <security :type="type" />
  </div>
</template>

<script>
import Security from '@/views/settings/agents/agent/security/security.vue';

export default {
  components: {
    Security,
  },
  data: () => ({}),
  computed: {
    type() {
      switch (this.$route.params.log) {
        case 'apps':
          return 1;
        case 'forbidden':
          return 2;
        case 'allowed':
          return 5;
        case 'text':
          return 3;
        case 'files':
          return 4;
        default:
          throw Error(`Unknown default log ${this.$route.params.log}`);
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  overflow: auto;
  height: 100%;
  flex-direction: column;
  display: flex;
}
</style>
