<template>
  <modal
    header-text=""
    :hide-footer="true"
    :hide-cross="false"
    class="selectModal"
    @close="$emit('close')"
  >
    <scrollable-container :class="$style.scrollableContainer">
      <table v-if="apps.length > 0" :class="$style.table">
        <tbody v-for="app in apps" :key="app.id">
          <div :class="$style.row" @click="expand(app)">
            <div
              :class="[
                $style.expandButton,
                { [$style.expanded]: app.isExpanded },
              ]"
            />
            <img
              :class="$style.iconFolder"
              src="@/assets/images/icons/computer/folder.svg"
            >
            <div>{{ app.label }}</div>
          </div>
          <tr
            v-for="child in app.children"
            v-show="app.isExpanded"
            :key="child.id"
            :class="$style.childRow"
            @dblclick="$emit('input', child.label)"
          >
            {{
              child.label
            }}
          </tr>
        </tbody>
      </table>
    </scrollable-container>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { getAppsList } from '@/api/methods/security';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: { Modal, ScrollableContainer },
  props: ['type'],
  data: () => ({
    apps: [],
  }),
  watch: {
    async type(newValue, oldValue) {
      if (oldValue !== newValue) {
        await this.getApps();
      }
    },
  },
  async created() {
    if (this.type === 1 || this.type === 2 || this.type === 5) {
      await this.getApps();
    }
  },
  methods: {
    expand(app) {
      app.isExpanded = !app.isExpanded;
    },
    async getApps() {
      this.apps = [];
      if (this.type === 1) {
        this.promise = await getAppsList(1).then(({ data }) => {
          if (data) {
            this.apps = data.result;
          }
        });
      } else {
        this.promise = await getAppsList(2).then(({ data }) => {
          if (data) {
            this.apps = data.result;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.scrollableContainer {
  height: 300px;
  margin: 0px 15px 15px 15px;
}
.table {
  width: 100%;
}
.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 1;
  margin-left: -17px;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
.iconFolder {
  padding-right: 5px;
}
.row {
  display: flex;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  padding: 5px 20px;
  align-items: center;
  position: relative;
  &:hover {
    background: $light-gray;
  }
}
.childRow {
  display: flex;
  cursor: pointer;
  line-height: 10px;
  font-size: 13px;
  padding: 5px 30px;
  align-items: center;
  &:hover {
    background: $light-gray;
  }
}
</style>

<style >
.selectModal .settings-modal_modal_3LaCC {
  width: 400px !important;
}
</style>
