<template>
  <modal
    header-text=""
    button-text="Удалить"
    @action="deleteItem"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      {{ output }}
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { deleteProfile, deleteUser } from '@/api/methods/settings';
import { deleteRule, deleteCategory } from '@/api/methods/security';

export default {
  components: { Modal },
  props: ['selected', 'type'],
  computed: {
    output() {
      switch (this.type) {
        case 'user':
          return 'Вы уверены, что хотите удалить пользователя?';
        case 'profile':
          return 'Вы уверены, что хотите удалить профиль?';
        case 'ruleCategory':
          return 'Вы уверены, что хотите удалить группу правил?';
        default:
          return 'Вы уверены, что хотите удалить запись?';
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async deleteItem() {
      if (this.type === 'user') await deleteUser(this.selected);
      if (this.type === 'profile') await deleteProfile(this.selected);
      if (this.type === 'rule') await deleteRule(this.selected);
      if (this.type === 'ruleCategory') {
        await deleteCategory(
          this.selected.id,
          this.selected.profile,
          this.selected.type,
        );
      }
      this.close();
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
</style>
