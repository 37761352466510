import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

function getSettings(link) {
  return axios.post(link).then((response) => {
    if (response.data.success) { return response; }

    openToast(response.data.message, 'error');
    response.data = null;

    return response;
  });
}

export function getSecurityRules(id, type, ruleType) {
  return getSettings(`/security/secRules/?id=${id}&type=${type}&ruleType=${ruleType}`);
}

export function deleteRule(id) {
  return axios.post(`/security/deleteRule/?id=${id}`).then((response) => {
    if (response.data.success) {
      openToast('Запись успешно удалена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function deleteCategory(id, profile, type) {
  return axios.post(`/security/deleteCategory/?id=${id}&profile=${profile}&type=${type}`).then((response) => {
    if (response.data.success) {
      openToast('Группа правил успешно удалена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editRule(id, name, dang, comment, caption, type) {
  return axios.post('/security/editRule', { id, name, dang, comment, caption, type }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function editCategory(id, name) {
  return axios.post('/security/editCategory', { id, name }).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addCategory(name, type) {
  return axios.post('/security/addCategory', { name, type }).then((response) => {
    if (response.data.success) {
      openToast('Группа успешно добавлена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function linkCategory(id, categoryId) {
  return axios.post(`/security/linkCategory/?id=${id}&categoryId=${categoryId}`).then((response) => {
    if (response.data.success) {
      openToast('Изменения успешно сохранены', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function addRule(name, dang, type, comment, caption, actType) {
  return axios.post('/security/addRule', { name, dang, type, comment, caption, actType }).then((response) => {
    if (response.data.success) {
      openToast('Правило успешно добавлено', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function getAppsList(type) {
  return getSettings(`/security/appsList/?type=${type}`);
}
